import { FunnelId } from 'constants/funnel';
import { IMGS_PATH } from 'constants/image';
import clsx from 'clsx';
import ExternalFunnelButton from 'components/Shared/ExternalFunnel/ExternalFunnelButton';
import Icon from 'components/ui/Icon';
import Link from 'components/ui/Link';
import config from 'config';
import { useAnalytics } from 'hooks/useAnalytics';
import { useTranslation } from 'hooks/useTranslation';
import Button from 'components/Qds/Button';
import styles from './Hero.module.scss';
import HeroCarousel from './HeroCarousel';

const Hero = (): JSX.Element => {
  const { locale, t } = useTranslation();
  const { trackEvent } = useAnalytics();

  const renderQunosuiteButton = (className?: string): JSX.Element => {
    return (
      <Link
        href={
          locale === 'de' ? 'https://qunosuite.de/' : 'https://qunosuite.com/'
        }
      >
        <a
          className={clsx(styles.qunosuiteButton, className)}
          title={t('exploreQunosuite')}
          onClick={() =>
            trackEvent({
              category: 'CTA',
              action: 'CTA|Click',
              label: 'CTA|Home|TopBanner|Qunosuite',
            })
          }
        >
          {t('exploreQunosuite')}
        </a>
      </Link>
    );
  };

  return (
    <>
      {config.homepage.qunosuite.includes(locale) && (
        <div className={styles.qunosuiteDesktop}>
          <div className={styles.qunosuiteDesktopInner}>
            <p className={styles.qunosuiteDesktopText}>
              <span>{t('introducingQunosuite')}</span> &mdash;{' '}
              {t('ourPatientManagementSoftware')}.
            </p>
            <p className={styles.qunosuiteDesktopActions}>
              <Link href={`/${locale}/qunosuite`}>
                <a
                  className={styles.qunosuiteDesktopPress}
                  title={t('seePressRelease')}
                >
                  {t('seePressRelease')}
                </a>
              </Link>
              {renderQunosuiteButton()}
            </p>
          </div>
        </div>
      )}
      <div className={styles.hero}>
        <div className={styles.heroGrid}>
          <div className={styles.heroLeftColumn}>
            <h1 className={styles.headline}>{t('home.hero.headline')}</h1>
            <p className={styles.text}>{t('home.hero.text')}</p>
            <p className={styles.usp}>
              <Icon className={styles.heroCheck} type="Check" color="#155263" />
              {t('home.hero.usp_1')}
            </p>
            <p className={styles.usp}>
              <Icon className={styles.heroCheck} type="Check" color="#155263" />
              {t('home.hero.usp_2')}
            </p>
            <p className={styles.usp}>
              <Icon className={styles.heroCheck} type="Check" color="#155263" />
              {t('home.hero.usp_3')}
            </p>
            <ExternalFunnelButton
              funnelId={FunnelId.CONTACT_FORM}
              renderButton={(openFunnel) => {
                return (
                  <Button
                    className="mt-8"
                    fullWidth="mobile"
                    size="large"
                    shadow={false}
                    onClick={() => {
                      trackEvent({
                        category: 'CTA',
                        action: 'CTA|Click',
                        label: 'CTA|Home|Hero|GetStarted',
                      });

                      openFunnel();
                    }}
                  >
                    <span className="md:px-28">{t('home.getStarted')}</span>
                  </Button>
                );
              }}
            />
          </div>
          <div className={styles.heroRightColumn}>
            <svg
              className={styles.blob}
              width="385"
              height="417"
              viewBox="0 0 385 417"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M356.175 300.496C343.542 340.527 318.978 372.482 282.483 396.36C245.988 420.238 208.791 423.398 170.892 405.841C132.994 388.283 93.1651 368.795 51.4064 347.375C9.6477 325.955 -6.66978 291.893 2.45397 245.191C11.5777 198.488 25.0879 150.205 42.9845 100.343C60.8811 50.4796 97.9025 19.052 154.049 6.0595C210.195 -6.93294 264.06 0.967866 315.644 29.7619C367.229 58.556 390.038 100.869 384.072 156.702C378.107 212.534 368.808 260.466 356.175 300.496Z"
                fill="#ffca58"
              />
            </svg>
            <HeroCarousel
              slides={[
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-anja.png`,
                  icon: 'Balance',
                  speciality: {
                    en: 'weight-loss-surgery',
                    de: 'operative-gewichtsreduktion',
                  },
                  treatment: {
                    en: 'gastric-bypass-surgery',
                    de: 'magenbypass-operation',
                  },
                  treatmentLabel: t('treatment.gastricBypass'),
                  name: 'Anja',
                  country: t('countries.unitedKingdom'),
                  text: t('home.hero.review.anja'),
                  show: ['de', 'en'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-michael.png`,
                  icon: 'PlasticSurgery',
                  speciality: {
                    en: 'plastic-surgery',
                    de: 'plastische-chirurgie',
                    nl: 'plastische-chirurgie',
                    es: 'cirugia-plastica',
                    it: 'chirurgia-plastica',
                    fr: 'chirurgie-esthetique',
                  },
                  treatment: {
                    en: 'hair-transplant',
                    de: 'haartransplantation',
                    nl: 'haartransplantatie',
                    es: 'injerto-capilar',
                    it: 'trapianto-di-capelli',
                    fr: 'greffe-de-cheveux',
                  },
                  treatmentLabel: t('treatment.hairTransplant'),
                  name: 'Michael',
                  country: t('countries.unitedKingdom'),
                  text: t('home.hero.review.michael'),
                  show: ['de', 'en', 'nl', 'es', 'it', 'fr'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-courtney.png`,
                  icon: 'Tooth',
                  speciality: {
                    en: 'dentistry',
                    de: 'zahnmedizin',
                  },
                  treatment: {
                    en: 'veneers',
                    de: 'veneers',
                  },
                  treatmentLabel: t('treatment.veneers'),
                  name: 'Courtney',
                  country: t('countries.unitedKingdom'),
                  text: t('home.hero.review.courtney'),
                  show: ['de', 'en', 'fr', 'it', 'es', 'nl'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-coree.png`,
                  icon: 'Tooth',
                  speciality: {
                    en: 'dentistry',
                    de: 'zahnmedizin',
                  },
                  treatment: {
                    en: 'teeth-cleaning',
                    de: 'zahnreinigung',
                  },
                  treatmentLabel: t('treatment.teethCleaning'),
                  name: 'Coree',
                  country: t('countries.germany'),
                  text: t('home.hero.review.coree'),
                  show: ['de', 'en'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-carl.png`,
                  icon: 'Tooth',
                  speciality: {
                    en: 'dentistry',
                    de: 'zahnmedizin',
                  },
                  treatment: {
                    en: 'dental-crown',
                    de: 'zahnkrone',
                  },
                  treatmentLabel: t('treatment.dentalCrowns'),
                  name: 'Carl',
                  country: t('countries.unitedStates'),
                  text: t('home.hero.review.carl'),
                  show: ['de', 'en'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-eamon.png`,
                  icon: 'PlasticSurgery',
                  speciality: {
                    en: 'plastic-surgery',
                    de: 'plastische-chirurgie',
                    nl: 'plastische-chirurgie',
                    es: 'cirugia-plastica',
                    it: 'chirurgia-plastica',
                    fr: 'chirurgie-esthetique',
                  },
                  treatment: {
                    en: 'hair-transplant',
                    de: 'haartransplantation',
                    nl: 'haartransplantatie',
                    es: 'injerto-capilar',
                    it: 'trapianto-di-capelli',
                    fr: 'greffe-de-cheveux',
                  },
                  treatmentLabel: t('treatment.hairTransplant'),
                  name: 'Eamon',
                  country: t('countries.unitedStates'),
                  text: t('home.hero.review.eamon'),
                  className: styles.carouselItem,
                  show: [],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-norman.png`,
                  icon: 'Bone',
                  speciality: {
                    en: 'orthopedics',
                    de: 'orthopaedie',
                  },
                  treatment: {
                    en: 'spine-surgery',
                    de: 'wirbelsaeulenchirurgie',
                  },
                  treatmentLabel: t('treatment.spineSurgery'),
                  name: 'Norman',
                  country: t('countries.unitedKingdom'),
                  text: t('home.hero.review.norman'),
                  className: styles.carouselItem,
                  show: ['de', 'en'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-dionne.png`,
                  icon: 'PlasticSurgery',
                  speciality: {
                    en: 'plastic-surgery',
                    de: 'plastische-chirurgie',
                  },
                  treatment: {
                    en: 'complete-abdominoplasty',
                    de: 'komplette-bauchdeckenplastik',
                  },
                  treatmentLabel: t('treatment.abdominoplasty'),
                  name: 'Dionne',
                  country: t('countries.unitedKingdom'),
                  text: t('home.hero.review.dionne'),
                  className: styles.carouselItem,
                  show: ['de', 'en'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-emma.png`,
                  icon: 'PlasticSurgery',
                  speciality: {
                    en: 'plastic-surgery',
                    de: 'plastische-chirurgie',
                  },
                  treatment: {
                    en: 'breast-augmentation',
                    de: 'brustvergroesserung',
                  },
                  treatmentLabel: t('treatment.breastaug'),
                  name: 'Emma',
                  country: t('countries.unitedKingdom'),
                  text: t('home.hero.review.emma'),
                  className: styles.carouselItem,
                  show: ['de', 'en'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-andreas.png`,
                  icon: 'PlasticSurgery',
                  speciality: {
                    en: 'plastic-surgery',
                    de: 'plastische-chirurgie',
                  },
                  treatment: {
                    en: 'gynecomastia-surgery',
                    de: 'gynaekomastie-chirurgie',
                  },
                  treatmentLabel: t('treatment.gynecomastiaSurgery'),
                  name: 'Andreas',
                  country: t('countries.germany'),
                  text: t('home.hero.review.andreas'),
                  className: styles.carouselItem,
                  show: ['de', 'en'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-ihfan.png`,
                  icon: 'Cardiology',
                  speciality: {
                    en: 'cardiovascular-procedures',
                    de: 'kardiovaskulaere-eingriffe',
                  },
                  treatmentLabel: t('cardiologyConsultation'),
                  name: 'Ihfan',
                  country: t('countries.germany'),
                  text: t('home.hero.review.ihfan'),
                  className: styles.carouselItem,
                  show: ['de', 'en'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-chris.png`,
                  icon: 'PlasticSurgery',
                  speciality: {
                    en: 'plastic-surgery',
                    de: 'plastische-chirurgie',
                  },
                  treatment: {
                    en: 'hair-transplant',
                    de: 'haartransplantation',
                  },
                  treatmentLabel: t('treatment.hairTransplant'),
                  name: 'Chris',
                  country: t('countries.unitedKingdom'),
                  text: t('home.hero.review.chris'),
                  className: styles.carouselItem,
                  show: ['de', 'en'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-robert.png`,
                  icon: 'Tooth',
                  speciality: {
                    en: 'dentistry',
                    de: 'zahnmedizin',
                  },
                  treatment: {
                    en: 'dental-crown',
                    de: 'zahnkrone',
                  },
                  treatmentLabel: t('treatment.dentalCrowns'),
                  name: 'Robert',
                  country: t('countries.unitedKingdom'),
                  text: t('home.hero.review.robert'),
                  className: 'md:pl-12 md:pb-0',
                  show: ['de', 'en'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-taniya.png`,
                  icon: 'Tooth',
                  speciality: {
                    en: 'dentistry',
                    de: 'zahnmedizin',
                  },
                  treatment: {
                    en: 'veneers',
                    de: 'veneers',
                  },
                  treatmentLabel: t('treatment.veneers'),
                  name: 'Taniya',
                  country: t('countries.unitedKingdom'),
                  text: t('home.hero.review.taniya'),
                  className: styles.carouselItem,
                  show: ['de', 'en'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-alison.png`,
                  icon: 'PlasticSurgery',
                  speciality: {
                    en: 'plastic-surgery',
                    de: 'plastische-chirurgie',
                  },
                  treatment: {
                    en: 'rhinoplasty',
                    de: 'nasenkorrektur',
                  },
                  treatmentLabel: t('treatment.rhinoplasty'),
                  name: 'Alison',
                  country: t('countries.ireland'),
                  text: t('home.hero.review.alison'),
                  className: styles.carouselItem,
                  show: ['de', 'en'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-roland.png`,
                  icon: 'Neurology',
                  speciality: {
                    en: 'neurology',
                    de: 'neurologie',
                  },
                  treatmentLabel: t('neurologyConsultation'),
                  name: 'Roland',
                  country: t('countries.germany'),
                  text: t('home.hero.review.roland'),
                  className: styles.carouselItem,
                  show: ['de', 'en'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-maddie.png`,
                  icon: 'PlasticSurgery',
                  speciality: {
                    en: 'plastic-surgery',
                    de: 'plastische-chirurgie',
                  },
                  treatment: {
                    en: 'sex-reassignment-surgery-male-to-female',
                    de: 'geschlechtsangleichende-operation-mann-zu-frau',
                  },
                  treatmentLabel: t(
                    'treatment.sexReassignmentSurgeryMaleFemale',
                  ),
                  name: 'Maddie',
                  country: t('countries.unitedStates'),
                  text: t('home.hero.review.maddie'),
                  className: styles.carouselItem,
                  show: ['de', 'en'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-amalia.png`,
                  icon: 'Cardiology',
                  speciality: {
                    en: 'cardiovascular-procedures',
                    de: 'kardiovaskulaere-eingriffe',
                  },
                  treatmentLabel: t('treatment.heartSurgery'),
                  name: 'Amalia',
                  country: t('countries.romania'),
                  text: t('home.hero.review.amalia'),
                  className: styles.carouselItem,
                  show: ['de', 'en'],
                },
              ]}
            />
          </div>
        </div>
      </div>

      {config.homepage.qunosuite.includes(locale) && (
        <div className={styles.qunosuiteMobile}>
          <div className={styles.qunosuiteMobileInner}>
            <p className={styles.qunosuiteMobileText}>
              <span>{t('introducingQunosuite')}</span> &mdash;{' '}
              {t('ourPatientManagementSoftware')}.
            </p>
            <p className={styles.qunosuiteMobileActions}>
              <Link href={`/${locale}/qunosuite`}>
                <a
                  className={styles.qunosuiteMobilePress}
                  title={t('seePressRelease')}
                >
                  {t('seePressRelease')}
                </a>
              </Link>
              {renderQunosuiteButton('mb-4')}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Hero;
