import clsx from 'clsx';
import { useRef } from 'react';
import Carousel from 'react-multi-carousel';
import { Direction } from 'types/Utilities';
import Icon from 'components/ui/Icon';
import Image from 'components/ui/Image';
import Link from 'components/ui/Link';
import Stars from 'components/ui/Stars';
import config from 'config';
import { useTranslation } from 'hooks/useTranslation';
import { useVerticalSwipePreventer } from 'hooks/useVerticalSwipePreventer';
import styles from './Carousel.module.scss';

type CustomDotProps = {
  index?: number;
  onClick?: () => void;
  active?: boolean;
};

const CustomDot = ({ index, onClick, active }: CustomDotProps): JSX.Element => {
  return (
    <li
      onClick={() => {
        onClick?.();
      }}
    >
      <div
        key={`dot_${index}`}
        className={clsx(
          'bg-navy rounded-full mx-1 transition duration-300 ease-in-out',
          !active ? 'opacity-50 w-2 h-2' : 'w-3 h-3',
        )}
      />
    </li>
  );
};

type CustomArrowProps = {
  onClick?: () => void;
  direction?: string;
};

const CustomArrow = ({ onClick, direction }: CustomArrowProps): JSX.Element => {
  const leftArrowCss = direction === 'left' ? 'md:block lg:hidden' : '';

  return (
    <Icon
      className={clsx(
        'shrink-0 hidden absolute md:block',
        `${direction}-0`,
        leftArrowCss,
      )}
      type="Arrow"
      direction={direction === 'left' ? Direction.LEFT : Direction.RIGHT}
      color="#5f6258"
      onClick={() => {
        onClick?.();
      }}
    />
  );
};

type CarouselProps = {
  slides: {
    className?: string;
    country: string;
    icon: string;
    image: string;
    name: string;
    show: string[];
    speciality: {
      en?: string;
      de?: string;
      nl?: string;
      es?: string;
      it?: string;
      fr?: string;
    };
    treatment?: {
      en?: string;
      de?: string;
      nl?: string;
      es?: string;
      it?: string;
      fr?: string;
    };
    text: string;
    treatmentLabel: string;
  }[];
};

const HeroCarousel = ({ slides }: CarouselProps): JSX.Element | null => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { locale } = useTranslation();
  useVerticalSwipePreventer(containerRef);

  if (!slides.length) {
    return null;
  }

  const filteredSlides = slides.filter((slide) => slide.show.includes(locale));

  const responsive = {
    all: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
      showDots: true,
    },
  };

  return (
    <div
      className={clsx(
        'my-6 sm:mt-12 sm:mb-0 lg-my-8 max-w-screen-xl mx-auto md:px-4 lg:h-auto',
        styles.slide,
      )}
      ref={containerRef}
    >
      <Carousel
        arrows
        showDots
        responsive={responsive}
        removeArrowOnDeviceType={['mobile']}
        containerClass="flex relative items-center overflow-hidden"
        sliderClass="flex relative flex-row list-none p-0 m-0"
        itemClass={styles.carouselItem}
        draggable
        renderDotsOutside
        customDot={<CustomDot />}
        dotListClass={clsx('flex items-center lg:hidden', styles.dotsWrap)}
        infinite
        customLeftArrow={<CustomArrow direction="left" />}
        customRightArrow={<CustomArrow direction="right" />}
        deviceType="all"
        ssr
      >
        {filteredSlides.map((slide, i) => {
          const specialtyForLocale =
            slide.speciality[locale as keyof typeof slide.speciality];

          const treatmentForLocale =
            slide.treatment?.[locale as keyof typeof slide.treatment];

          return (
            <div
              className={clsx(
                'relative flex justify-center shrink-0',
                styles.slide,
              )}
              key={i}
            >
              <div className="flex lg:block flex-col justify-between relative mx-2 lg:w-4/5 xl:w-10/12">
                <div className="lg:flex justify-end">
                  <Image
                    src={slide.image}
                    className={clsx(
                      'rounded-md md:ml-40 lg:ml-56 xl:ml-64',
                      slide.className,
                    )}
                    height={400}
                    width={391}
                    alt={slide.name}
                    priority={i === 0}
                  />
                </div>
                <div className="absolute bottom-0 lg:relative w-full lg:-mt-48 bg-white rounded-lg z-10 p-4 lg:w-54pr lg:ml-4 text-primary shadow-md mb-6">
                  <div className="flex items-center">
                    <div className="bg-secondary rounded-full p-3 flex items-center justify-center mr-2">
                      <Icon
                        className="w-6 h-6"
                        type={slide.icon}
                        color="#155263"
                      />
                    </div>
                    <p className="text-base">
                      <Link
                        href={`${
                          slide.treatment
                            ? `/${locale}/${specialtyForLocale}/${treatmentForLocale}`
                            : `/${locale}/${specialtyForLocale}`
                        }`}
                      >
                        <a
                          title={slide.treatmentLabel}
                          className="text-navy mb-1 block hover:underline"
                        >
                          {slide.treatmentLabel}
                        </a>
                      </Link>
                      <Stars rating={5} />
                    </p>
                  </div>
                  <div className="mt-4">
                    <p className="text-base">
                      <span className="font-bold">{slide.name}</span>
                      {config.homepage.reviewerLocation.includes(locale) && (
                        <>, {slide.country}</>
                      )}
                      :
                    </p>
                    <p className="text-base">{slide.text}</p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default HeroCarousel;
